import React, { Suspense, useMemo } from 'react';
import './App.less';
import { ConnectionProvider, useConnectionConfig } from './utils/connection';
import { GlobalStyle } from './global_style';
import { Spin } from 'antd';
import ErrorBoundary from './components/ErrorBoundary';
import { Routes } from './routes';
import { PreferencesProvider } from './utils/preferences';
import { ReferrerProvider } from './utils/referrer';
import { LedgerWalletAdapter, SafecoinWalletAdapter } from "@safecoin/wallet-adapter-wallets";
import { WalletAdapterNetwork } from "@safecoin/wallet-adapter-base";
import { WalletProvider } from "@safecoin/wallet-adapter-react";

function AppImpl() {
  const { endpoint } = useConnectionConfig();
  const network = useMemo(() => endpoint as WalletAdapterNetwork, [endpoint]);
  const wallets = useMemo(
    () => [
      new SafecoinWalletAdapter({ provider: 'https://walletdev.safecoin.org', network }),
      new LedgerWalletAdapter(),
    ],
    [network],
  );
  return (
    <ReferrerProvider>
      <WalletProvider autoConnect wallets={wallets}>
        <PreferencesProvider>
          <Suspense fallback={() => <Spin size="large" />}>
            <Routes />
          </Suspense>
        </PreferencesProvider>
      </WalletProvider>
    </ReferrerProvider>
  );
}

export default function App() {
  return (
    <Suspense fallback={() => <Spin size="large" />}>
      <GlobalStyle />
      <ErrorBoundary>
        <ConnectionProvider>
          <AppImpl />
        </ConnectionProvider>
      </ErrorBoundary>
    </Suspense>
  );
}
