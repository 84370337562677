import React, { useContext } from 'react';
import { PublicKey } from '@safecoin/web3.js';

interface ReferrerContextValues {
  usdcRef: PublicKey | undefined;
  usdtRef: PublicKey | undefined;
}

const ReferrerContext: React.Context<null | ReferrerContextValues> = React.createContext<null | ReferrerContextValues>(
  null,
);

export const ReferrerProvider = ({ children }) => {
  const usdcRef: PublicKey | undefined = process.env.REACT_APP_USDC_REFERRAL_FEES_ADDRESS
      ? new PublicKey(process.env.REACT_APP_USDC_REFERRAL_FEES_ADDRESS)
      : undefined;
  const usdtRef: PublicKey | undefined = process.env.REACT_APP_USDT_REFERRAL_FEES_ADDRESS
      ? new PublicKey(process.env.REACT_APP_USDT_REFERRAL_FEES_ADDRESS)
      : undefined;
  return (
    <ReferrerContext.Provider
      value={{ usdcRef, usdtRef }}
    >
      {children}
    </ReferrerContext.Provider>
  );
};

export const useReferrer = () => {
  const context = useContext(ReferrerContext);
  if (!context) throw new Error('Missing referrer context');
  return {
    usdcRef: context.usdcRef,
    usdtRef: context.usdtRef,
  };
};
