import { SafecoinTrade } from './types';

export default class SafecoinApi {
  private static BASE_API_URL: string = process.env.REACT_APP_SAFECOIN_API_URL
    ? process.env.REACT_APP_SAFECOIN_API_URL
    : '/api/v1/';
  static DATA_FEED_URL = SafecoinApi.BASE_API_URL + 'tradingview';

  static async get(path: string) {
    try {
      const response = await fetch(SafecoinApi.BASE_API_URL + path);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson.success ? responseJson.data : null;
      }
    } catch (err) {
      console.log(`Error fetching from Safecoin API ${path}: ${err}`);
    }
    return null;
  }

  static async getRecentTrades(marketAddress: string): Promise<SafecoinTrade[] | null> {
    console.log(process.env);
    return SafecoinApi.get(`trades/${marketAddress}`);
  }
}
