import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  input[type=number]::-webkit-inner-spin-button {
    opacity: 0;
  }

  input[type=number]:hover::-webkit-inner-spin-button,
  input[type=number]:focus::-webkit-inner-spin-button {
    opacity: 0.25;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e7eaf0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c6cede;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b2b9c7;
  }

  .ant-slider-track, .ant-slider:hover .ant-slider-track {
    background-color: #2abdd2;
    opacity: 0.75;
  }

  .ant-slider-track,
  .ant-slider ant-slider-track:hover {
    background-color: #2abdd2;
    opacity: 0.75;
  }

  .ant-slider-dot-active,
  .ant-slider-handle,
  .ant-slider-handle-click-focused,
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border: 2px solid #2abdd2;
  }

  .ant-table-container table > thead > tr:first-child th {
    border-bottom: none;
  }

  .ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
    border-top: 1px solid #434a59 !important;
  }

  .ant-select-item-option-content {
    img {
      margin-right: 4px;
    }
  }

  @-webkit-keyframes highlight {
    from {
      background-color: #2abdd2;
    }
    to {
      background-color: #0B779CFF;
    }
  }

  @-moz-keyframes highlight {
    from {
      background-color: #2abdd2;
    }
    to {
      background-color: #0B779CFF;
    }
  }

  @-keyframes highlight {
    from {
      background-color: #2abdd2;
    }

    to {
      background-color: #0B779CFF;
    }
  }
  .flash {
    -moz-animation: highlight 0.5s ease 0s 1 alternate;
    -webkit-animation: highlight 0.5s ease 0s 1 alternate;
    animation: highlight 0.5s ease 0s 1 alternate;
  }`;
