export class OrderRow {
  side: 'buy' | 'sell';
  price?: number;
  size?: number;

  constructor(side, price, size) {
    this.side = side;
    this.price = price;
    this.size = size;
  }
}
