import { PublicKey } from '@safecoin/web3.js';

export const DEX_PID = new PublicKey('539CXdDRdumiPxPcySxWkqibb9vYH9dWdb8C9vphoumq');

// TODO: fix for Safecoin
export const SRM_MINT = new PublicKey('SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt');

// TODO: fix for Safecoin
export const USDC_MINT = new PublicKey('EkHTAzbNbqmcqjNvyiQyv4LERqEHnuf7ZNELyuzXndfA');

// TODO: fix for Safecoin
export const USDT_MINT = new PublicKey('D8MRnsZQwDKZT31TqXkZCM972u9kYAx2VubbWi6FngA7');

// TODO: looks like we can still use this mint for Safecoin, it's just some fake mint to distinguish SAFE and wrapped SAFE
// Arbitrary mint to represent SAFE (not wrapped SAFE).
export const SAFE_MINT = new PublicKey('Ejmc1UB4EsES5oAaRN63SpoxMJidt3ZGBrqrZk49vjTZ');
export const WRAPPED_SAFE_MINT = new PublicKey('Safe111111111111111111111111111111111111111');
