import React, { useContext, useMemo } from "react";
import { TokenInfo, TokenListContainer } from "@safecoin/safe-token-registry";
import { SAFE_MINT } from "../utils/pubkeys";

type TokenListContextType = {
  tokenMap: Map<string, TokenInfo>;
  swappableTokens: TokenInfo[];
};
const TokenListContext = React.createContext<null | TokenListContextType>(null);

const SAFE_TOKEN_INFO: TokenInfo = {
  chainId: 101,
  address: SAFE_MINT.toString(),
  name: "Native SAFE",
  decimals: 9,
  symbol: "SAFE",
  logoURI:
    "https://www.safecoin.org/fileadmin/Images/Ecosystem/SafeCoin/Styleguide-Ressources/svg/SafeCoin_Icon.svg",
  tags: [],
  extensions: {
    // TODO: determine these parameters for SAFE
    website: "https://safecoin.org/",
    serumV3Usdc: "FEzQgUW2269ggpPnLBuuWCCQi7iwwb55XUzWAxW9yY6k",
    serumV3Usdt: "GUbiEe3b3mxow9vacTm8VqPq2QZcAk8NoCkqLSDTUUEA",
    coingeckoId: "safecoin",
    // waterfallbot: "https://t.me/SOLwaterfall",
  },
};

export function TokenListContextProvider(props: {
  tokenList?: TokenListContainer,
  children: any
}) {
  const tokenList = useMemo(() => {
    const list: TokenInfo[] = props.tokenList ? props.tokenList.filterByClusterSlug("mainnet-beta").getList() : [];
    // Manually add a fake SAFE mint for the native token. The component is
    // opinionated in that it distinguishes between wrapped SAFE and SAFE.
    list.push(SAFE_TOKEN_INFO);
    return list;
  }, [props?.tokenList]);

  // Token map for quick lookup.
  const tokenMap = useMemo(() => {
    const tokenMap = new Map();
    tokenList.forEach((t: TokenInfo) => {
      tokenMap.set(t.address, t);
    });
    return tokenMap;
  }, [tokenList]);

  // Tokens with USD(x) quoted markets.
  const swappableTokens = useMemo(() => {
    const tokens = tokenList.filter((t: TokenInfo) => {
      const isUsdxQuoted =
        t.extensions?.serumV3Usdt || t.extensions?.serumV3Usdc;
      return isUsdxQuoted;
    });
    tokens.sort((a: TokenInfo, b: TokenInfo) =>
      a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0
    );
    return tokens;
  }, [tokenList]);

  return (
    <TokenListContext.Provider
      value={{
        tokenMap,
        swappableTokens,
      }}
    >
      {props.children}
    </TokenListContext.Provider>
  );
}

export function useTokenListContext(): TokenListContextType {
  const ctx = useContext(TokenListContext);
  if (ctx === null) {
    throw new Error("Context not available");
  }
  return ctx;
}

export function useTokenMap(): Map<string, TokenInfo> {
  const { tokenMap } = useTokenListContext();
  return tokenMap;
}

export function useSwappableTokens() {
  const { swappableTokens } =
    useTokenListContext();
  return { swappableTokens };
}
