import '@fontsource/roboto';
import { useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { AnchorProvider } from '@safely/anchor';
// @ts-ignore
import { ConfirmOptions, Signer, Transaction, TransactionSignature } from '@safecoin/web3.js';
import { TokenListContainer } from '@safecoin/safe-token-registry';
import Swap from '../components/Swap';
import { SafelyTokenListProvider } from '@safely/swap';
import { useWallet } from "@safecoin/wallet-adapter-react";
import { useConnection } from "../utils/connection";
import { notify } from '../utils/notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default function SwapPage() {
  const styles = useStyles();
  const [tokenList, setTokenList] = useState<TokenListContainer | undefined>(undefined);
  const connection = useConnection();
  const { publicKey, signTransaction, signAllTransactions } = useWallet();

  const provider = useMemo(() => {
    if (!publicKey || !signTransaction || !signAllTransactions) {
      return;
    }

    const opts: ConfirmOptions = {
      preflightCommitment: 'recent',
      commitment: 'recent',
    };

    const provider = new NotifyingProvider(connection, {
      signTransaction: signTransaction,
      signAllTransactions: signAllTransactions,
      publicKey: publicKey
    }, opts);
    return provider;
  }, [connection, publicKey, signTransaction, signAllTransactions]);

  useEffect(() => {
    new SafelyTokenListProvider().resolve().then(setTokenList);
  }, [setTokenList]);

  return (
    <Grid container justifyContent='center' alignItems='center' className={styles.root}>
      <Swap provider={provider} tokenList={tokenList} />
    </Grid>
  );
}

// Custom provider to display notifications whenever a transaction is sent.
//
// Note that this is an Anchor wallet/network provider--not a React provider,
// so all transactions will be flowing through here, which allows us to
// hook in to display all transactions sent from the `Swap` component
// as notifications in the parent app.
class NotifyingProvider extends AnchorProvider {
  // TODO: test it and fix in case of need
  // async send(
  //   tx: Transaction,
  //   signers?: Array<Signer | undefined>,
  //   opts?: ConfirmOptions
  // ): Promise<TransactionSignature> {
  //   try {
  //     const txSig = await super.send(tx, signers, opts);
  //     this.onTransaction(txSig);
  //     return txSig;
  //   } catch (err) {
  //     if (err instanceof Error || err === undefined) {
  //       this.onTransaction(undefined, err);
  //     }
  //     return "";
  //   }
  // }

  async sendAll(
    txs: Array<{ tx: Transaction; signers?: Signer[] }>,
    opts?: ConfirmOptions,
  ): Promise<Array<TransactionSignature>> {
    try {
      txs.forEach((tx) => {
        notify({
          message: 'Sending transaction...',
          type: 'success',
        });
      });
      const txSigs = await super.sendAll(txs, opts);
      txSigs.forEach((sig) => {
        notify({
          message: 'Transaction success',
          txid: sig,
          type: 'success',
        });
      });
      return txSigs;
    } catch (err) {
      if (err instanceof Error || err === undefined) {
        notify({
          message: `Error: ${err.toString()}`,
          type: 'error',
        });
      }
      return [];
    }
  }
}
