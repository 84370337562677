import React, {useContext, useEffect, useState} from "react";
import * as assert from "assert";
import {useAsync} from "react-async-hook";
import {MintLayout} from "@safecoin/safe-token";
import {PublicKey} from "@safecoin/web3.js";
import * as anchor from "@safely/anchor";
import {AnchorProvider} from "@safely/anchor";
import {Swap as SwapClient} from "@safely/swap";
import {Market, OpenOrders, Orderbook as OrderbookSide,} from "@safely/safely";
import {DEX_PID, SAFE_MINT, WRAPPED_SAFE_MINT,} from "../utils/pubkeys";
import {useTokenMap} from "./TokenList";
import {setMintCache} from "./Token";

const BASE_TAKER_FEE_BPS = 0.0022;
export const FEE_MULTIPLIER = 1 - BASE_TAKER_FEE_BPS;

type DexContextType = {
  // Maps market address to open orders accounts.
  openOrders: Map<string, Array<OpenOrders>>;
  closeOpenOrders: (openOrder: OpenOrders) => void;
  provider?: AnchorProvider;
  swapClient?: SwapClient;
};
const DexContext = React.createContext<DexContextType | null>(null);

export function DexContextProvider(props: {
  provider?: AnchorProvider;
  swapClient?: SwapClient;
  children: any;
}) {
  const [ooAccounts, setOoAccounts] = useState<Map<string, Array<OpenOrders>>>(
    new Map()
  );
  const provider = props.provider;
  const swapClient = props.swapClient;

  // Removes the given open orders from the context.
  const closeOpenOrders = async (openOrder: OpenOrders) => {
    const newOoAccounts = new Map(ooAccounts);
    const openOrders = newOoAccounts
      .get(openOrder.market.toString())
      ?.filter((oo: OpenOrders) => !oo.address.equals(openOrder.address));
    if (openOrders && openOrders.length > 0) {
      newOoAccounts.set(openOrder.market.toString(), openOrders);
    } else {
      newOoAccounts.delete(openOrder.market.toString());
    }
    setOoAccounts(newOoAccounts);
  };

  // Three operations:
  //
  // 1. Fetch all open orders accounts for the connected wallet.
  // 2. Batch fetch all market accounts for those open orders.
  // 3. Batch fetch all mints associated with the markets.
  useEffect(() => {
    if (!provider || !provider.wallet.publicKey) {
      setOoAccounts(new Map());
      return;
    }
    OpenOrders.findForOwner(
      provider.connection,
      provider.wallet.publicKey,
      DEX_PID
    ).then(async (openOrders) => {
      const newOoAccounts = new Map();
      let markets = new Set<string>();
      openOrders.forEach((oo) => {
        markets.add(oo.market.toString());
        if (newOoAccounts.get(oo.market.toString())) {
          newOoAccounts.get(oo.market.toString()).push(oo);
        } else {
          newOoAccounts.set(oo.market.toString(), [oo]);
        }
      });
      if (markets.size > 100) {
        // Punt request chunking until there's user demand.
        throw new Error(
          "Too many markets. Please file an issue to update this"
        );
      }
      const multipleMarkets = await anchor.utils.rpc.getMultipleAccounts(
        provider.connection,
        Array.from(markets.values()).map((m) => new PublicKey(m))
      );
      const marketClients = multipleMarkets.map((programAccount) => {
        return {
          publicKey: programAccount?.publicKey,
          account: new Market(
            Market.getLayout(DEX_PID).decode(programAccount?.account.data),
            -1, // Set below so that we can batch fetch mints.
            -1, // Set below so that we can batch fetch mints.
            provider.opts,
            DEX_PID
          ),
        };
      });

      setOoAccounts(newOoAccounts);

      // Batch fetch all the mints, since we know we'll need them at some
      // point.
      const mintPubkeys = Array.from(
        new Set<string>(
          marketClients
            .map((m) => [
              m.account.baseMintAddress.toString(),
              m.account.quoteMintAddress.toString(),
            ])
            .flat()
        ).values()
      ).map((pk) => new PublicKey(pk));

      if (mintPubkeys.length > 100) {
        // Punt request chunking until there's user demand.
        throw new Error("Too many mints. Please file an issue to update this");
      }

      const mints = await anchor.utils.rpc.getMultipleAccounts(
        provider.connection,
        mintPubkeys
      );
      const mintInfos = mints.map((mint) => {
        const mintInfo = MintLayout.decode(mint!.account.data);
        setMintCache(mint!.publicKey, mintInfo);
        return { publicKey: mint!.publicKey, mintInfo };
      });

      marketClients.forEach((m) => {
        const baseMintInfo = mintInfos.filter((mint) =>
          mint.publicKey.equals(m.account.baseMintAddress)
        )[0];
        const quoteMintInfo = mintInfos.filter((mint) =>
          mint.publicKey.equals(m.account.quoteMintAddress)
        )[0];
        assert.ok(baseMintInfo && quoteMintInfo);
        // @ts-ignore
        m.account._baseSplTokenDecimals = baseMintInfo.mintInfo.decimals;
        // @ts-ignore
        m.account._quoteSplTokenDecimals = quoteMintInfo.mintInfo.decimals;
        _MARKET_CACHE.set(
          m.publicKey!.toString(),
          new Promise<Market>((resolve) => resolve(m.account))
        );
      });
    });
  }, [
    provider,
    provider?.connection,
    provider?.wallet.publicKey,
    provider?.opts,
  ]);
  return (
    <DexContext.Provider
      value={{
        openOrders: ooAccounts,
        closeOpenOrders,
        provider,
        swapClient,
      }}
    >
      {props.children}
    </DexContext.Provider>
  );
}

export function useDexContext(): DexContextType {
  const ctx = useContext(DexContext);
  if (ctx === null) {
    throw new Error("Context not available");
  }
  return ctx;
}

export function useOpenOrders(): Map<string, Array<OpenOrders>> {
  const ctx = useDexContext();
  return ctx.openOrders;
}

// Lazy load a given market.
export function useMarket(market?: PublicKey): Market | undefined {
  const { provider } = useDexContext();

  const asyncMarket = useAsync(async () => {
    if (!provider || !market) {
      return undefined;
    }
    if (_MARKET_CACHE.get(market.toString())) {
      return _MARKET_CACHE.get(market.toString());
    }

    const marketClient = new Promise<Market>(async (resolve) => {
      // TODO: if we already have the mints, then pass them through to the
      //       market client here to save a network request.
      const marketClient = await Market.load(
        provider.connection,
        market,
          (provider as AnchorProvider).opts,
        DEX_PID
      );
      resolve(marketClient);
    });

    _MARKET_CACHE.set(market.toString(), marketClient);
    return marketClient;
  }, [provider, provider?.connection, market]);

  if (asyncMarket.result) {
    return asyncMarket.result;
  }

  return undefined;
}

// Lazy load the orderbook for a given market.
export function useOrderbook(market?: PublicKey): Orderbook | undefined {
  const { provider } = useDexContext();
  const marketClient = useMarket(market);
  const [refresh, setRefresh] = useState(0);

  const asyncOrderbook = useAsync(async () => {
    if (!provider || !market || !marketClient) {
      return undefined;
    }
    if (_ORDERBOOK_CACHE.get(market.toString())) {
      return _ORDERBOOK_CACHE.get(market.toString());
    }

    const orderbook = new Promise<Orderbook>(async (resolve) => {
      const [bids, asks] = await Promise.all([
        marketClient.loadBids(provider.connection),
        marketClient.loadAsks(provider.connection),
      ]);

      resolve({
        bids,
        asks,
      });
    });

    _ORDERBOOK_CACHE.set(market.toString(), orderbook);

    return orderbook;
  }, [refresh, provider, provider?.connection, market, marketClient]);

  // Stream in bids updates.
  useEffect(() => {
    if (!provider) {
      return () => {};
    }
    let listener: number | undefined;
    if (marketClient?.bidsAddress) {
      listener = provider.connection.onAccountChange(
        marketClient?.bidsAddress,
        async (info) => {
          const bids = OrderbookSide.decode(marketClient, info.data);
          const orderbook = await _ORDERBOOK_CACHE.get(
            marketClient.address.toString()
          );
          const oldBestBid = orderbook?.bids.items(true).next().value;
          const newBestBid = bids.items(true).next().value;
          if (
            orderbook &&
            oldBestBid &&
            newBestBid &&
            oldBestBid.price !== newBestBid.price
          ) {
            orderbook.bids = bids;
            setRefresh((r) => r + 1);
          }
        }
      );
    }
    return () => {
      if (listener) {
        provider.connection.removeAccountChangeListener(
          listener
        );
      }
    };
  }, [
    marketClient,
    marketClient?.bidsAddress,
    provider,
    provider?.connection,
  ]);

  // Stream in asks updates.
  useEffect(() => {
    if (!provider) {
      return () => {};
    }
    let listener: number | undefined;
    if (marketClient?.asksAddress) {
      listener = provider.connection.onAccountChange(
        marketClient?.asksAddress,
        async (info) => {
          const asks = OrderbookSide.decode(marketClient, info.data);
          const orderbook = await _ORDERBOOK_CACHE.get(
            marketClient.address.toString()
          );
          const oldBestOffer = orderbook?.asks.items(false).next().value;
          const newBestOffer = asks.items(false).next().value;
          if (
            orderbook &&
            oldBestOffer &&
            newBestOffer &&
            oldBestOffer.price !== newBestOffer.price
          ) {
            orderbook.asks = asks;
            setRefresh((r) => r + 1);
          }
        }
      );
    }
    return () => {
      if (listener) {
        provider.connection.removeAccountChangeListener(
          listener
        );
      }
    };
  }, [
    marketClient,
    marketClient?.bidsAddress,
    provider,
    provider?.connection,
  ]);

  if (asyncOrderbook.result) {
    return asyncOrderbook.result;
  }

  return undefined;
}

export function useMarketName(market: PublicKey): string | null {
  const tokenMap = useTokenMap();
  const marketClient = useMarket(market);
  if (!marketClient) {
    return null;
  }
  const baseTicker = marketClient
    ? tokenMap.get(marketClient?.baseMintAddress.toString())?.symbol
    : "-";
  const quoteTicker = marketClient
    ? tokenMap.get(marketClient?.quoteMintAddress.toString())?.symbol
    : "-";
  return `${baseTicker} / ${quoteTicker}`;
}

// Fair price for a given market, as defined by the mid.
export function useBbo(market?: PublicKey): Bbo | undefined {
  const orderbook = useOrderbook(market);
  if (orderbook === undefined) {
    return undefined;
  }
  const bestBid = orderbook.bids.items(true).next().value;
  const bestOffer = orderbook.asks.items(false).next().value;
  if (!bestBid && !bestOffer) {
    return {};
  }
  if (!bestBid) {
    return { bestOffer: bestOffer.price };
  }
  if (!bestOffer) {
    return { bestBid: bestBid.price };
  }
  const mid = (bestBid.price + bestOffer.price) / 2.0;
  return { bestBid: bestBid.price, bestOffer: bestOffer.price, mid };
}

// Fair price for a theoretical toMint/fromMint market. I.e., the number
// of `fromMint` tokens to purchase a single `toMint` token. Aggregates
// across a trade route, if needed.
export function useFairRoute(
  fromMint: PublicKey,
  toMint: PublicKey
): number | undefined {
  const route = useRoute(fromMint, toMint);
  const fromBbo = useBbo(route ? route[0] : undefined);
  const fromMarket = useMarket(route ? route[0] : undefined);
  const toBbo = useBbo(route ? route[1] : undefined);

  if (route === null) {
    return undefined;
  }

  if (route.length === 1 && fromBbo !== undefined) {
    if (fromMarket === undefined) {
      return undefined;
    }
    if (
      fromMarket?.baseMintAddress.equals(fromMint) ||
      (fromMarket?.baseMintAddress.equals(WRAPPED_SAFE_MINT) &&
        fromMint.equals(SAFE_MINT))
    ) {
      return fromBbo.bestBid && 1.0 / fromBbo.bestBid;
    } else {
      return fromBbo.bestOffer && fromBbo.bestOffer;
    }
  }
  if (
    fromBbo === undefined ||
    fromBbo.bestBid === undefined ||
    toBbo === undefined ||
    toBbo.bestOffer === undefined
  ) {
    return undefined;
  }
  return toBbo.bestOffer / fromBbo.bestBid;
}

export function useRoute(
  fromMint: PublicKey,
  toMint: PublicKey
): Array<PublicKey> | null {
  const route = useRouteVerbose(fromMint, toMint);
  if (route === null) {
    return null;
  }
  return route.markets;
}

// Types of routes.
//
// 1. Direct trades on USDC quoted markets.
// 2. Transitive trades across two USDC qutoed markets.
// 3. Wormhole <-> Sollet one-to-one swap markets. NOT IMPLEMENTED FOR SAFECOIN.
// 4. Wormhole <-> Native one-to-one swap markets. NOT IMPLEMENTED FOR SAFECOIN.
// TODO: probably we should also support direct market trades without using USDX.
// TODO: we should also implement SafeBridge markets (see the old delete wormhole implementation for it)
export function useRouteVerbose(
  fromMint: PublicKey,
  toMint: PublicKey
): { markets: Array<PublicKey>; kind: RouteKind } | null {
  const { swapClient } = useDexContext();
  const asyncRoute = useAsync(async () => {
    if (!swapClient) {
      return null;
    }
    const markets = swapClient.route(
      fromMint.equals(SAFE_MINT) ? WRAPPED_SAFE_MINT : fromMint,
      toMint.equals(SAFE_MINT) ? WRAPPED_SAFE_MINT : toMint
    );
    if (markets === null) {
      return null;
    }
    const kind: RouteKind = "usdx";
    return { markets, kind };
  }, [fromMint, toMint, swapClient]);

  if (asyncRoute.result) {
    return asyncRoute.result;
  }
  return null;
}

type Orderbook = {
  bids: OrderbookSide;
  asks: OrderbookSide;
};

type RouteKind = "usdx";

type Bbo = {
  bestBid?: number;
  bestOffer?: number;
  mid?: number;
};

const _ORDERBOOK_CACHE = new Map<string, Promise<Orderbook>>();
const _MARKET_CACHE = new Map<string, Promise<Market>>();
