import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import TradePage from './pages/TradePage';
import React from 'react';
import BasicLayout from './components/BasicLayout';
import {getTradePageUrl} from './utils/markets';
import OpenOrdersPage from "./pages/OpenOrdersPage";
import BalancesPage from "./pages/BalancesPage";
import ListNewMarketPage from "./pages/ListNewMarketPage";
import SwapPage from "./pages/SwapPage";

export function Routes() {
  return (
    <>
      <HashRouter basename={'/'}>
        <BasicLayout>
          <Switch>
            <Route exact path="/">
              <Redirect to={getTradePageUrl()} />
            </Route>
            <Route exact path="/market/:marketAddress">
              <TradePage />
            </Route>
            <Route exact path="/orders" component={OpenOrdersPage} />
            <Route exact path="/balances" component={BalancesPage} />
            <Route exact path="/swap" component={SwapPage} />
            <Route
              exact
              path="/list-new-market"
              component={ListNewMarketPage}
            />
          </Switch>
        </BasicLayout>
      </HashRouter>
    </>
  );
}
