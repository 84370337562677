import React from 'react';
import styled, { CSSProperties } from 'styled-components';

const Wrapper = styled.div`
  margin: 5px;
  padding: 20px;
  background-color: white;
`;

export default function FloatingElement({
  style = undefined,
  children,
  stretchVertical = false,
}: {
  style?: CSSProperties;
  children?: any;
  stretchVertical?: boolean;
}) {
  return (
    <Wrapper
      style={{
        height: stretchVertical ? 'calc(100% - 10px)' : undefined,
        ...style,
      }}
    >
      {children}
    </Wrapper>
  );
}
